import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import OtpInput from "react-otp-input";
import { AppContext } from "../../../Context/Context";
import Button from "../../../Components/Button/Button";
import { authentication } from "../../../Api/authentication";
import "./OTP.css";
import Icon from "../../../Assets/Images/otpIcon1.png";
import { Routes } from "../../../Constant/Routes/Routes";
import Modal from "react-bootstrap/Modal";

interface OTPProp {
  handleToUpdate: () => void;
  stepLength: number;
  isEmail: boolean;
  existingEmail: string;
}

const OTP: React.FC<OTPProp> = ({
  handleToUpdate,
  stepLength,
  isEmail,
  existingEmail,
}) => {
  const { phone_Number, userId, setToken, loginEmail, isExistingUser } =
    React.useContext(AppContext);
  const [code, setCode] = React.useState("");
  const [error, setError] = React.useState(false);
  const [counter, setCounter] = React.useState(60);
  const [loading, setLoading] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [tooltip, setTooltip] = React.useState(""); // Tooltip state
  const [showTooltip, setShowTooltip] = React.useState(false); // Visibility state for tooltip
  const history = useHistory();

  const push_token = "";
  const counterHandler = useCallback(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  React.useEffect(() => {
    if (stepLength === 3) {
      counterHandler();
    }
  }, [stepLength, counterHandler]);

  const showCustomTooltip = (message: string) => {
    setTooltip(message);
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 3000); // Show tooltip for 3 seconds
  };

  const ResendOtp = async () => {
    handleClose();
    const response = await authentication.signIn(phone_Number, loginEmail);
    if (response.status) {
      setError(false);
      setCode("");
      showCustomTooltip("OTP successfully sent."); // Show tooltip instead of alert
      setCounter(60);
    } else {
      showCustomTooltip("Something went wrong.");
    }
  };

  const handleConfirmation = async () => {
    setError(false);
    setLoading(true);
    let localUserId : any = sessionStorage.getItem("userId");
    try {
      const response = await authentication.confirmation(
        localUserId,
        code,
        push_token
      );

      if (response.status) {
        setToken(response.message.token);
        sessionStorage.setItem("token", response.message.token);

        if (response.message.is_new_user) {
          await authentication.slack_email_notification(
            response.message.token,
            response.message.is_new_user,
            phone_Number,
            loginEmail
          );
        }
        setError(false);
        history.replace(Routes.PERSONAL_INFO_SCREEN);
      } else {
        setError(true);
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  return (
    <div className="container">
      <div>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          backdrop="static"
          onHide={handleClose}
          className="modal-height"
        >
          <Modal.Header className="bg-white border-0 color-black"  closeButton>
            <Modal.Title> </Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-white">
            <Button
              disabled={counter !== 0}
              label="Resend Pin"
              className={counter === 0 ? "activeButton" : "deactive-button"}
              onClick={ResendOtp}
            />
            {!isExistingUser ? (
              <Button
                label="Edit Email"
                className="editemail"
                onClick={handleToUpdate}
              />
            ) : null}
          </Modal.Body>
        </Modal>
      </div>

      {/* Tooltip Section */}
      {showTooltip && <div className="custom-tooltip">{tooltip}</div>}

      <div className="header">
        <div className="help_btn">
          <p onClick={handleShow} className="fw-bold">Help</p>
        </div>
        <div className="mainHeader">
          <h4 className="mainTitle">Enter Verification Code</h4>
          <img src={Icon} alt="logo" />
        </div>
        <div className="otpMessageContainer">
          <h5 className="titleCode">
            Enter the code sent to your email{" "}
            {/* {isEmail ? existingEmail || loginEmail : phone_Number} */}
          </h5>
        </div>
        <div className="otpContainer">
          <OtpInput
            value={code}
            onChange={setCode}
            inputStyle="inputFields"
            isInputNum
          />
        </div>
        {error ? (
          <h6 className="errorText">
            {`Incorrect code. Please try again!
            Click HELP for more options.`}
          </h6>
        ) : null}
        <div className="continueBtn">
          <Button
            label="Verify"
            className={code.length > 3 ? "activeButton" : "deactive-button"}
            disabled={code.length <= 3 || loading}
            onClick={handleConfirmation}
            icon={
              loading ? <i className="fas fa-circle-notch fa-spin"></i> : null
            }
          />
        </div>
        <div>
          <p className="footer">
            {counter !== 0 ? (
              <>
                Didn’t receive a code? You can resend in{" "}
                <span className="footerSpan">
                  00-{counter < 10 ? `0${counter}` : counter}
                </span>
              </>
            ) : (
              <>
                Didn’t receive a code? You can resend from{" "}
                <span
                  className="resend-link"
                  onClick={ResendOtp}
                  style={{ cursor: "pointer", color: "rgb(216, 25, 255)" }}
                >
                  here
                </span>
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OTP;
